import React, { useState, createContext } from 'react';
import './SuccessfulCheckout.css'
import { Col, Container, Row, Card } from 'react-bootstrap';

function SuccessfulCheckout() {

  return (
    <>
    <Container className="SuccessfulCheckoutWindow">
      <Card className="SuccessfulCheckoutCard">
        <h1>Checkout Successful!</h1>
        <p>Thank you for ordering! You will recieve tracking information via your provided email address once your order has been printed and shipped.</p>
      </Card>
    </Container>
    </>
  );
}

export default SuccessfulCheckout;
