import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { StlViewer } from "react-stl-viewer";
import { useNavigate } from 'react-router-dom';
import { Button, Container, Form, Row, Col, Card } from 'react-bootstrap';
import './Cart.css';
import { HttpPopupContext } from '../HTTPPopup/HttpPopupContext';
import CustomSTLViewer from '../UploadPage/CustomSTLViewer';
import { AddressElement, Elements } from '@stripe/react-stripe-js';

function Cart(props) {
  const [buyLinkReceived, setBuyLinkReceived] = useState(false);
  const [stripePaymentLink, setStripePaymentLink] = useState();
  const [shippingInfo, setShippingInfo] = useState();
  const [addressValidated, setAddressValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setResponse } = useContext(HttpPopupContext);

  const style = {};

  const [formData, setFormData] = useState({
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    email: ''
  });

  const states = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
  ];

  const validateForm = () => {
    const missingFields = Object.entries(formData).filter(([key, value]) => key !== 'street2' && !value);
    if (missingFields.length > 0) {
      alert('Please fill all required fields.');
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const addressValidator = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    //console.log(formData);
    setIsLoading(true);
    try {
      const response = await axios.post('https://printforgers.com/backend/addressValidator', { cart: props.cart, address: formData }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setShippingInfo(response.data.amount / 100);
      alert('Address validated successfully, shipping quote updated.');
      setAddressValidated(true); // Set to true if successful
    } catch (error) {
      setShippingInfo();
      setResponse(error.response) //sets the error code to be displayed
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (stripePaymentLink) {
      window.open(stripePaymentLink);
      window.location.reload();
    }
  }, [stripePaymentLink]);

  const paymentLinkCreator = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post('https://printforgers.com/backend/checkoutSessionCreator', { cart: props.cart, address: formData }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.url) {
        window.location.href = response.data.url;  // Directly redirect to Stripe's Checkout page
      } else {
        console.error('Failed to receive checkout URL');
      }
    } 
    catch (error) {
      setResponse(error.response);
      console.error('Error creating payment link:', error);
    } 
    finally {
      setIsLoading(false);
    }
  };

  if (!props.cart || !props.cart.STLArray || props.cart.STLArray.length === 0) {
    return (
      <Container className="EmptyCart">
        <Card className='CartCard'>
          <h1>Your Cart</h1>
          <p>There's nothing in your cart yet. Go to the <a onClick={() => navigate('/upload')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>Instant Quote</a> page to upload your files.</p>
        </Card>
      </Container>
    );
  }

  return (
    <>
      {!addressValidated && (
        <Container>
        <Card className='ShippingInfo'>
          <h1>Shipping Information</h1>
          <Form className='Shipping-form' onSubmit={addressValidator}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Street</Form.Label>
              <Form.Control type="text" name="street1" value={formData.street1} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Apt/Suite/Unit Number</Form.Label>
              <Form.Control type="text" name="street2" value={formData.street2} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Select name="state" value={formData.state} onChange={handleChange} required>
                <option value="">Select a state</option>
                {states.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Zip</Form.Label>
              <Form.Control type="text" name="zip" value={formData.zip} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" name="country" value={formData.country} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
            </Form.Group>
            <Button variant='primary' onClick={() => navigate('/options')} disabled={isLoading}>
              {isLoading ? 'Calculating...' : 'Back to Order Options'}
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              {isLoading ? 'Calculating...' : 'Calculate Shipping'}
            </Button>
          </Form>
        </Card>
        </Container>
      )}
      {addressValidated && (
        <Container>
        <Card className="Cart">
          <h1>Your Cart</h1>
          <hr />
          {props.cart.STLArray.map((object, index) => (
            <React.Fragment key={index}>
              <Row className='OptionListInCartRow'>
                <Col xs={6} className="LeftSideOrderInfo">
                  <Row>
                    <Col xs={12} md={4}>
                    <CustomSTLViewer
                      url={`https://printforgers.com/backend/fileSender?id=${encodeURIComponent(object.id)}&fileName=${encodeURIComponent(object.fileName)}`}
                    />
                    </Col>
                  </Row>
                  <Row className='STLAttributes'>
                    <Col xs={12}><p>File: {object.fileName}</p></Col>
                    <Col xs={12}><p>Quantity: {object.quantity}</p></Col>
                    <Col xs={12}><p>{object.dimensions.gcodex.toFixed(2)}mm x {object.dimensions.gcodey.toFixed(2)}mm x {object.dimensions.gcodez.toFixed(2)}mm</p></Col>
                  </Row>
                </Col>
                <Col className="RightSideOrderInfo" xs={6} md={6}>
                  <p>${(object.price * object.quantity).toFixed(2)}</p>
                </Col>
              </Row>
              <hr />
            </React.Fragment>
          ))}
          <br />
          <Row className='OptionListInCartRow'>
            <Col className="LeftSideOrderInfo" xs={6} md={6}><p>Material: {props.cart.material}</p></Col>
            <Col className="RightSideOrderInfo" xs={6} md={6}><p>${props.cart.materialCost}</p></Col>
          </Row>
          <Row className='OptionListInCartRow'>
            <Col className="LeftSideOrderInfo" xs={6} md={6}><p>Color: {props.cart.color}</p></Col>
            <Col className="RightSideOrderInfo" xs={6} md={6}><p>${props.cart.colorCost}</p></Col>
          </Row>
          <Row className='OptionListInCartRow'>
            <Col className="LeftSideOrderInfo" xs={6} md={6}><p>Infill: {props.cart.infill}%</p></Col>
            <Col className="RightSideOrderInfo" xs={6} md={6}><p>${props.cart.infillCost}</p></Col>
          </Row>
          <hr />
          <h1>Order Total</h1>
          <Row className='OptionListInCartRow'>
            <Col className="LeftSideOrderInfo" xs={6} md={6}><p>Estimated arrival time:</p></Col>
            <Col className="RightSideOrderInfo" xs={6} md={6}><p>{Math.ceil(props.cart.totalPrintTime / 60 / 60 / 12) + 2}-{Math.ceil(props.cart.totalPrintTime / 60 / 60 / 12) + 9} business days</p></Col>
          </Row>
          <Row className='OptionListInCartRow'>
            <Col className="LeftSideOrderInfo" xs={6} md={6}><p>Order Subtotal:</p></Col>
            <Col className="RightSideOrderInfo" xs={6} md={6}><p>${props.cart.orderTotal}</p></Col>
          </Row>
          <Row className='OptionListInCartRow'>
            <Col className="LeftSideOrderInfo" xs={6} md={6}><p>Shipping Total:</p></Col>
            <Col className="RightSideOrderInfo" xs={6} md={6}>{shippingInfo ? <p>${shippingInfo}</p> : <p>Fill out shipping info</p>}</Col>
          </Row>
          <Row className='OptionListInCartRow'>
            <Col className="LeftSideOrderInfo" xs={6} md={6}><p>Order Total:</p></Col>
            <Col className="RightSideOrderInfo" xs={6} md={6}>{shippingInfo ? <p>${(+shippingInfo + +props.cart.orderTotal).toFixed(2)}</p> : <p>Fill out shipping info</p>}</Col>
          </Row>
          {shippingInfo && (
            <Button variant='secondary' className='ChangeShippingInfo' disabled={isLoading} onClick={() => setAddressValidated(false)}>
              {isLoading ? 'Loading...' : 'Change Shipping'}
            </Button>
          )}
          <br />
          {shippingInfo && (
            <Button variant='primary' disabled={isLoading} onClick={paymentLinkCreator}>
              {isLoading ? 'Loading...' : 'Order Now'}
            </Button>
          )}
        </Card>
        </Container>
      )}
    </>
  );
}

export default Cart;
