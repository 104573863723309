// DemoModeBanner.js
import React from 'react';
import { Alert } from 'react-bootstrap';

function DemoModeBanner() {
  return (
    <Alert variant="warning" className="text-center mb-0">
      This site is now in demo mode due to time constraints on the owner. Please email parker@printforgers.com if you have any questions.
    </Alert>
  );
}

export default DemoModeBanner;
