import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './FAQ.css';
import { useNavigate } from 'react-router-dom';


function FAQ() {
  const navigate = useNavigate();
  return (
    <Container className="FAQ">
      <Card className="FAQCard">
        <Row className="FAQStartingRow align-items-center">
          <Col xs={12} className="text-center">
            <h1>Frequently Asked Questions</h1>
          </Col>
        </Row>
        <Row className="mt-5 align-items-center">
          <Col xs={12} className="text-center text-md-left">
            <h3 className="faq-section-title">How do I use Printforgers and get 3d models?</h3>
            <p className="faq-text">
              An in-depth guide for how to use Printforgers.com and get 3D models is found on the <a onClick={() => navigate('/howtoget3dmodels')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>How to Get 3D Models</a> page. If you have more questions on how to use the service, do not hesitate to send an <a href="mailto:parker@printforgers.com">email</a>.
            </p>
          </Col>
        </Row>
        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">What are support structures in 3D printing?</h3>
            <p className="faq-text">
              Support structures are temporary structures that are printed along with your model to support overhanging parts during the printing process. These structures are necessary to ensure the integrity and accuracy of complex prints.
            </p>
          </Col>
          <Col className="text-center" xs={12} md={6}>
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/FAQPage/pre-parsed-stl-model.webp`}
              alt="Support Structures Example"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>
        
        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">Will the support structures be removed from my prints?</h3>
            <p className="faq-text">
              Typically, support structures are not removed from the prints. It is up to the user to remove them. Removing support structures can be a delicate process and may require some post-processing to achieve the desired finish.
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/FAQPage/parsed-stl-model-with-supports.webp`}
              alt="Support Structures Example"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>
        
        <Row className="mt-5 align-items-center">
          <Col xs={12} className="text-center text-md-left">
            <h3 className="faq-section-title">How do I place an order?</h3>
            <p className="faq-text">
              You can place an order by uploading your STL file on our <a onClick={() => navigate('/upload')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>Upload Page</a>. The instant quote process will guide you through the steps to finalize your order.
            </p>
          </Col>
        </Row>
        
        <Row className="mt-5 align-items-center">
          <Col xs={12} className="text-center text-md-left">
            <h3 className="faq-section-title">What materials can I choose from?</h3>
            <p className="faq-text">
              We offer a variety of materials for 3D printing, including PLA, ABS, PETG, and more. Each material has its own properties and benefits, which can be selected based on your project needs.
            </p>
          </Col>
        </Row>
        
        <Row className="mt-5 align-items-center">
          <Col xs={12} className="text-center text-md-left">
            <h3 className="faq-section-title">How do I contact you for special requests?</h3>
            <p className="faq-text">
              For specialized orders or if the instant quote process can't perform your desired task, feel free to send me an <a href="mailto:parker@printforgers.com">email</a>. I'm happy to discuss your specific needs and provide a custom solution.
            </p>
          </Col>
        </Row>
        
        <Row className="mt-5 align-items-center">
          <Col xs={12} className="text-center text-md-left">
            <h3 className="faq-section-title">What is your return policy?</h3>
            <p className="faq-text">
              Due to the custom nature of 3D printing, we do not accept returns. However, if there is an issue with your print, please contact us, and we will do our best to resolve it.
            </p>
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xs={12} className="text-center text-md-left">
            <h3 className="faq-section-title">Where can I find free STL files for 3D printing?</h3>
            <p className="faq-text">
              There are several great resources online where you can find free STL files for 3D printing:
              </p>
              <ul>
                <li>
                <p className="faq-text"><a href="https://www.printables.com/" target="_blank" rel="noopener noreferrer">Printables</a>: An excellent site offering free STL files, with a focus on high-quality designs and community feedback.</p>
                </li>
                <li>
                <p className="faq-text"><a href="https://www.thingiverse.com/" target="_blank" rel="noopener noreferrer">Thingiverse</a>: One of the largest collections of free STL files. You can find designs for various projects and interests.</p>
                </li>
                <li>
                <p className="faq-text"><a href="https://www.myminifactory.com/" target="_blank" rel="noopener noreferrer">MyMiniFactory</a>: A curated platform with a vast selection of free and premium STL files, ensuring high-quality prints.</p>
                </li>
              </ul>
              <p className="faq-text">These platforms provide a wide range of designs, from practical tools to decorative items, ensuring you find something that suits your needs.</p>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default FAQ;
