import { useContext, useEffect, useState } from 'react';
import { HttpPopupContext } from './HttpPopupContext';
import './HttpPopup.css';

function HttpPopup() {
  const { response, setResponse } = useContext(HttpPopupContext);
  const [popupColor, setPopupColor] = useState("LightGreen");

  // This useEffect sets the background color of the HTTP response popup and has it timeout after 8 seconds
  useEffect(() => {
    if (response) {
      if (response.status === 200) {
        setPopupColor("LightGreen");
      } else {
        setPopupColor("LightPink");
      }
      setTimeout(() => { // Wait 8 seconds before hiding the response message
        setResponse(null); // Changed from false to null for consistency
      }, 8000);
    }
  }, [response, setResponse]);

  return (
    <div className="HttpPopup" style={{ backgroundColor: popupColor }}>
      {/* Below will show the message if one is set, or show nothing once message state has been set to empty */}
      {response ?
        <p>Message: {response.data?.message || "No message"}</p> : // Safely accessing response.data.message
        <></>
      }
    </div>
  );
}

export default HttpPopup;
