import React, { useState, createContext } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";


function Materials() {
const [cart, setCart] = useState([]);


  return (
    <div className="Materials">
        <h1>Materials</h1>
    </div>
  );
}

export default Materials;
