import React from 'react';
import { Container, Card } from 'react-bootstrap';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <Container className="privacy-container">
      <Card className="privacy-card">
        <Card.Body>
          <Card.Title as="h1" className="text-center">Privacy Policy</Card.Title>
          
          <section className="privacy-section">
            <h2>1. Introduction</h2>
            <p>This Privacy Policy describes how Printforgers ("we", "our", "us") collects, uses, and shares personal information of users ("you", "your") of our website and services.</p>
          </section>
          
          <section className="privacy-section">
            <h2>2. Information We Collect</h2>
            <p>We collect information you provide directly to us, such as when you create an account, upload files, place an order, subscribe to our newsletter, or contact us. This information may include your name, email address, payment information, and any other details you provide.</p>
          </section>
          
          <section className="privacy-section">
            <h2>3. How We Use Your Information</h2>
            <p>We use your information to provide and improve our services, process transactions, send communications, and personalize your experience. We may also use your information for security purposes, to comply with legal obligations, and for other purposes with your consent.</p>
          </section>
          
          <section className="privacy-section">
            <h2>4. Sharing Your Information</h2>
            <p>We may share your information with third parties to process transactions and provide our services. We do not sell your personal information. We may also share information as required by law, to protect our rights, and with your consent.</p>
          </section>
          
          <section className="privacy-section">
            <h2>5. Your Rights</h2>
            <p>You have the right to access, correct, delete, and restrict the use of your personal information. You may also withdraw your consent for us to use your data at any time. To exercise these rights, please contact us at support@printforgers.com.</p>
          </section>
          
          <section className="privacy-section">
            <h2>6. Data Security</h2>
            <p>We implement appropriate security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
          </section>
          
          <section className="privacy-section">
            <h2>7. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Your continued use of our services after any changes indicates your acceptance of the new Privacy Policy.</p>
          </section>
          
          <section className="privacy-section">
            <h2>8. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@printforgers.com">support@printforgers.com</a>.</p>
          </section>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default PrivacyPolicy;
