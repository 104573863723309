import React from 'react';
import { Container, Card } from 'react-bootstrap';
import './TermsOfUse.css';

function TermsOfUse() {
  return (
    <Container className="TermsWindow">
      <Card className="terms-card">
        <Card.Body>
          <Card.Title as="h1" className="text-center">Terms of Use</Card.Title>
          
          <section className="terms-section">
            <h2>1. Applicability</h2>
            <p>These Terms of Use (ToU) apply to any and all uses of the services provided by Printforgers, a 3D printing service company based in Texas, USA. By accessing or using our services, you agree to be bound by these ToU, whether you are a registered user or a guest.</p>
          </section>
          
          <section className="terms-section">
            <h2>2. Eligibility</h2>
            <p>By using our services, you represent and warrant that you are of legal age to form a binding contract with Printforgers and meet all of the foregoing eligibility requirements.</p>
          </section>
          
          <section className="terms-section">
            <h2>3. Compliance with Laws</h2>
            <p>As a user of Printforgers, you agree to comply with all applicable laws and regulations of the United States. This includes, but is not limited to, laws regarding export control, intellectual property, and import regulations. You must not use our services if you are located in a country that is subject to US government embargoes or if you are listed on any US government list of prohibited or restricted parties.</p>
          </section>
          
          <section className="terms-section">
            <h2>4. User Responsibilities</h2>
            <p>You are responsible for the content of your 3D model files and ensuring that they do not infringe on any third-party rights, including intellectual property rights. By submitting your files to Printforgers, you warrant that you have all necessary rights and permissions to use, reproduce, and distribute the content. You may not upload or print firearms or any content that is illegal, offensive, or violates any laws. Printforgers reserves the right to refuse any order and offer a partial or complete refund depending on the order size.</p>
          </section>
          
          <section className="terms-section">
            <h2>5. Prohibited Uses</h2>
            <p>You agree not to use Printforgers for any unlawful purposes or to upload any content that is illegal, offensive, or violates any laws. This includes, but is not limited to, content that is obscene, defamatory, threatening, or otherwise harmful.</p>
          </section>
          
          <section className="terms-section">
            <h2>6. Data Privacy</h2>
            <p>We value your privacy and are committed to protecting your personal information. Any personal data collected through your use of our services will be handled in accordance with our <a href="/privacy-policy">Privacy Policy</a>. We will not share your personal information with third parties without your consent, except as required by law.</p>
          </section>
          
          <section className="terms-section">
            <h2>7. Order Fulfillment and Support Structures</h2>
            <p>Printforgers will handle the printing and shipment of your 3D models based on the specifications you provide. You are responsible for providing accurate and complete information for your order. Printforgers is not liable for any issues arising from incorrect or incomplete information provided by you.</p>
            <p>Note: By placing an order, you acknowledge that you have read and understood our FAQ page regarding support structures and that support structures will typically not be removed from prints. For more information, please visit our <a href="/faq">FAQ page</a>.</p>
          </section>
          
          <section className="terms-section">
            <h2>8. Shipping Policy</h2>
            <p>Printforgers only ships orders within the United States. We do not process or ship orders outside the USA.</p>
          </section>
          
          <section className="terms-section">
            <h2>9. Liability and Disclaimer of Warranties</h2>
            <p>Printforgers provides its services "as is" without any warranties, express or implied. We do not guarantee that our services will be uninterrupted or error-free. To the fullest extent permitted by law, Printforgers disclaims all warranties, including the warranty of merchantability, non-infringement, and fitness for a particular purpose.</p>
          </section>
          
          <section className="terms-section">
            <h2>10. Limitation of Liability</h2>
            <p>In no event shall Printforgers be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of our services or any related services.</p>
          </section>
          
          <section className="terms-section">
            <h2>11. Changes to Terms of Use</h2>
            <p>Printforgers reserves the right to modify these ToU at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services after the posting of revised ToU means that you accept and agree to the changes.</p>
          </section>
          
          <section className="terms-section">
            <h2>12. Governing Law</h2>
            <p>These ToU and any disputes arising out of or related to these ToU or our services will be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of law principles.</p>
          </section>
          
          <section className="terms-section">
            <h2>13. Contact Information</h2>
            <p>If you have any questions about these ToU, please contact us at support@printforgers.com.</p>
          </section>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default TermsOfUse;
