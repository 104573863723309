import React, { useState, useRef, useContext } from "react";
import axios from 'axios';
import './UploadPage.css';
import { useNavigate } from "react-router-dom";
import { HttpPopupContext } from '../HTTPPopup/HttpPopupContext';
import { Button, Container, Row, Col, Spinner, Card } from 'react-bootstrap';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import CustomSTLViewer from './CustomSTLViewer';

function UploadPage(props) {
    const [userFile, setUserFile] = useState();
    const [taskId, setTaskId] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const fileInputRef = useRef(null);
    const fileSubmitRef = useRef(null);

    const [uploadProgressFromAxios, setUploadProgressFromAxios] = useState(0);
    const [downloadProgressFromAxios, setDownloadProgressFromAxios] = useState(0);

    const { setResponse } = useContext(HttpPopupContext);
    const navigate = useNavigate();

    const MAX_DIMENSIONS = { x: 420, y: 420, z: 480 };

    function fileInputted(e) {
        const file = e.target.files[0];
        const fileExtension = getFileExtension(file.name);

        if (fileExtension === "stl") {
            checkSTLDimensions(file).then(isValid => {
                if (isValid) {
                    setUserFile(e.target.files[0]);
                    const event = new Event('submit', {
                        bubbles: true,
                        cancelable: true
                    });
                    fileSubmitRef.current.dispatchEvent(event);
                } else {
                    alert('The dimensions of the STL file exceed the maximum allowed dimensions of 420x420x480 mm.');
                }
            });
        }

        function getFileExtension(fileName) {
            return fileName.split('.').pop().toLowerCase();
        }
    }

    async function checkSTLDimensions(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const contents = event.target.result;
                const loader = new STLLoader();
                const geometry = loader.parse(contents);

                const boundingBox = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                const dimensions = boundingBox.getSize(new THREE.Vector3());

                const isValid = dimensions.x <= MAX_DIMENSIONS.x && dimensions.y <= MAX_DIMENSIONS.y && dimensions.z <= MAX_DIMENSIONS.z;
                resolve(isValid);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    }

    async function uploadFile(e) {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target);
        try {
            const response = await axios.post('https://printforgers.com/backend/stlUpload', formData, {
                onUploadProgress: progressEvent => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgressFromAxios(progress);
                },
                onDownloadProgress: progressEvent => {
                    const downloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (downloadProgress >= 100) {
                        setUploadProgressFromAxios(0);
                        setDownloadProgressFromAxios(0);
                    }
                }
            });
            const { taskId } = response.data;
            setTaskId(taskId);
            pollUploadStatus(taskId);
        } catch (error) {
            console.error("Error uploading file:", error.response);
            setResponse(error.response);
            setLoading(false);
        }
    }

    async function pollUploadStatus(taskId) {
        const intervalId = setInterval(async () => {
            try {
                const response = await axios.get(`https://printforgers.com/backend/upload-status/${taskId}`);
                if (response.data.status === 'completed') {
                    clearInterval(intervalId);
                    setUploadProgressFromAxios(0);
                    const fileObject = {
                        fileName: response.data.fileData.fileName,
                        id: response.data.fileData.id,
                        quantity: 1,
                        price: 0,
                        size: [],
                        error: ''
                    };
                    props.setSTLArray([...props.STLArray, fileObject]);
                    setLoading(false);
                } else if (response.data.status === 'failed') {
                    clearInterval(intervalId);
                    console.error('Upload failed');
                    setResponse(response);
                    setLoading(false);
                } else {
                    console.log('Upload still in progress');
                }
            } catch (error) {
                console.error("Error polling upload status:", error.response);
                setResponse(error.response);
                setLoading(false);
            }
        }, 1000); // Poll every second
    }

    const handleSelectFileClick = () => {
        fileInputRef.current.click();
    };

    async function getBaseOrderPrice() {
        setLoading(true);
        try {
            const response = await axios.post('https://printforgers.com/backend/getBaseOrderPrice', props.STLArray);
            const { taskId } = response.data;
            setTaskId(taskId);
            pollTaskStatus(taskId);
        } catch (error) {
            console.error("Error getting base order price:", error.response);
            setResponse(error.response);
            setLoading(false);
        }
    }

    async function pollTaskStatus(taskId) {
        const intervalId = setInterval(async () => {
            try {
                const response = await axios.get(`https://printforgers.com/backend/task-status/${taskId}`);
                if (response.data.status === 'completed') {
                    clearInterval(intervalId);
                    //console.log('Task completed');
                    setResponse(response);
                    props.setSTLArray(response.data.returnObject);
                    setLoading(false);
                    navigate("/options");
                } else if (response.data.status === 'failed') {
                    clearInterval(intervalId);
                    console.error('Task failed');
                    setResponse(response);
                    setLoading(false);
                } else {
                    //console.log('Task still in progress');
                }
            } catch (error) {
                console.error("Error polling task status:", error.response);
                setResponse(error.response);
                setLoading(false);
            }
        }, 1000); // Poll every second
    }

    function plusIterator(index) {
        let originalArray = [...props.STLArray];
        originalArray[index].quantity += 1;
        props.setSTLArray(originalArray);
    }

    function minusIterator(index) {
        let originalArray = [...props.STLArray];
        if (originalArray[index].quantity <= 1) {
            originalArray.splice(index, 1);
        } else {
            originalArray[index].quantity -= 1;
        }
        props.setSTLArray(originalArray);
    }

    return (
        <Container className="InteractionWindow">
            <Card className="UploadCard">
            <h1>Upload Your Files to Get a Quote</h1>
            <p className="UploadPageNotice">Orders only ship in the USA, and the default unit of measurement for reading files is millimeters (mm). By uploading your file(s), you agree to our <a onClick={() => navigate('/terms-of-use')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>Terms of Use</a> and have read the <a onClick={() => navigate('/faq')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>FAQ Page</a>.</p>
            {uploadProgressFromAxios > 0 ? (
                <p>Uploading your file... {uploadProgressFromAxios}% uploaded to server</p>
            ) : (
                <div className="uploadField" onClick={handleSelectFileClick}>
                    <Button variant="primary" onClick={(e) => e.preventDefault()} disabled={isLoading}>
                        {isLoading ? 'Loading...' : 'Enter your STL file here'}
                    </Button>
                    <form onSubmit={uploadFile} ref={fileSubmitRef}>
                        <input type="file" onChange={fileInputted} name="file" accept=".stl,model/stl" style={{ display: 'none' }} ref={fileInputRef} />
                    </form>
                </div>
            )}
            <br />
            <Row className="STLViewerArray justify-content-between">
                {props.STLArray.map((object, index) => (
                    <Col key={index} className="STLViewerWindow" xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                        {object.fileName != null ? (
                            <>
                                <Col className="LeftSTLWindow">
                                    <CustomSTLViewer
                                        url={`https://printforgers.com/backend/fileSender?id=${encodeURIComponent(object.id)}&fileName=${encodeURIComponent(object.fileName)}`}
                                    />
                                </Col>
                                <Col className="RightSTLWindow">
                                    <p>File name: {object.fileName}</p>
                                    <div className="Quantity">
                                        <p>Quantity:</p>
                                        <div className="STLControls">
                                            <Button variant="primary" className="MinusButton" disabled={isLoading} onClick={() => { minusIterator(index) }}>-</Button>
                                            <input className="form-control mx-2" value={object.quantity} readOnly={true}/>
                                            <Button variant="primary" className="PlusButton" disabled={isLoading} onClick={() => { plusIterator(index) }}>+</Button>
                                        </div>
                                    </div>
                                </Col>
                            </>
                        ) : (
                            <p>Upload Progress: {uploadProgressFromAxios}%</p>
                        )}
                    </Col>
                ))}
            </Row>
            <br />
            {props.STLArray.length > 0 &&
                <Button variant="primary" disabled={isLoading} onClick={getBaseOrderPrice}>
                    {isLoading ? 'Loading...' : 'Calculate Prices'}
                </Button>
            }
            <p className="HavingTrouble">Having trouble? Check our guide on how to get 3D models and how to use Printforgers.com <a onClick={() => navigate('/howtoget3dmodels')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>here</a>.</p>
            </Card>
        </Container>
    );
}

export default UploadPage;