import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './HowToGet3DModels.css';
import { useNavigate } from 'react-router-dom';

function HowToGet3DModels() {
const navigate = useNavigate();
  return (
    <Container className="FAQ">
      <Card className="HowToCard">
        <Row className="FAQStartingRow align-items-center">
          <Col xs={12} className="text-center">
            <h1>How to Get 3D Models and Use Printforgers.com</h1>
          </Col>
        </Row>
        
        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">1. Finding 3D Models</h3>
            <p className="faq-text">
              Various websites have giant selections of free 3D model files. These are called .STL files. Listed below are two that we recommend.</p>
              <ul>
                <li>
                  <p className="faq-text"><a href="https://www.thingiverse.com/" target="_blank" rel="noopener noreferrer">Thingiverse</a>: One of the largest collections of free .STL files available online, covering a vast range of categories.</p>
                    </li>
                    <li>
                    <p className="faq-text"><a href="https://www.printables.com/" target="_blank" rel="noopener noreferrer">Printables</a>: Offers a rich selection of high-quality STL files, backed by community feedback.</p>
                    </li>
              </ul>
            
          </Col>
          <Col xs={12} md={6} className="text-center">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/HowToGet3DModels/thingiverse-picture.webp`}
              alt="Searching for 3D models online"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">2. Downloading the .STL Files</h3>
            <p className="faq-text">
              Once you've found a model you like, download the .STL file. These files are often packaged in a ZIP archive that may include multiple folders and files (e.g., documentation, images, or multiple parts of the model).
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/HowToGet3DModels/jeep-card-download.webp`}
              alt="Downloading STL files"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">3. Extracting Files from ZIP Archives</h3>
            <p className="faq-text">
              After downloading, the .zip file should appear in your downloads folder. You can click 'extract all' to turn it into a folder. On most computers, you can do this by right-clicking the ZIP file and selecting "Extract All." Make sure to locate the .STL file after extraction. It is likely inside the folder labeled 'files' if you use Thingiverse.
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/HowToGet3DModels/extract-all-image.webp`}
              alt="Extracting STL files from ZIP"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">4. Uploading to Printforgers.com</h3>
            <p className="faq-text">
              With your .STL file ready, head over to our <a onClick={() => navigate('/upload')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>Upload Page</a> to get an automated quote. Upload the .STL file, and our system will guide you through customizing your print.
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/HowToGet3DModels/upload-stl-image.webp`}
              alt="Uploading .STL files to Printforgers"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">5. Configuring Your Print</h3>
            <p className="faq-text">
              After uploading, you'll be able to configure your print settings, such as material type, color, and infill percentage. This step allows you to customize your print according to your needs.
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/HowToGet3DModels/options-page-image.webp`}
              alt="Configuring print settings"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3 className="faq-section-title">6. Completing Your Order</h3>
            <p className="faq-text">
              Once you're satisfied with the configuration, proceed to checkout to complete your order. Make sure to review your selections and enter your shipping information accurately.
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/HowToGet3DModels/checkout-page-image.webp`}
              alt="Completing your order on Printforgers"
              effect="opacity"
              rounded="true"
              fluid="true"
            />
          </Col>
        </Row>

      </Card>
    </Container>
  );
}

export default HowToGet3DModels;
