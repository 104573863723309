import React, { useState, useEffect } from 'react';
import { StlViewer } from "react-stl-viewer";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import './Options.css';
import CustomSTLViewer from '../UploadPage/CustomSTLViewer';
import SelectMaterial from './SelectMaterial';
import SelectColor from './SelectColor';
import SelectInfill from './SelectInfill';

function Options(props) {
    const [material, setMaterial] = useState("PLA");
    const [color, setColor] = useState('White');
    const [infill, setInfill] = useState(20);

    const [materialCost, setMaterialCost] = useState(0);
    const [colorCost, setColorCost] = useState(0);
    const [infillCost, setInfillCost] = useState(0);

    const [materialCostMultiplier, setMaterialCostMultiplier] = useState(0);
    const [colorCostMultiplier, setColorCostMultiplier] = useState(0);
    const [infillCostMultiplier, setInfillCostMultiplier] = useState(0);

    const [optionStep, setOptionStep] = useState("Material");

    const [orderTotal, setOrderTotal] = useState(0);
    const [totalPrintTime, setTotalPrintTime] = useState(0);
    const [baseTotal, setBaseTotal] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (props.STLArray.length === 0) {
            navigate("/upload");
        }
    }, [props.STLArray, navigate]);

    useEffect(() => {
        try {
            let baseTotal = 0;
            let timeTotal = 0;

            props.STLArray.forEach((object) => {
                baseTotal += object.price * object.quantity;
                timeTotal += object.time * object.quantity;
            });

            setBaseTotal(baseTotal);
            setTotalPrintTime(timeTotal);

            const matCost = baseTotal * materialCostMultiplier;
            setMaterialCost(matCost.toFixed(2));

            const colCost = baseTotal * colorCostMultiplier;
            setColorCost(colCost.toFixed(2));

            const infCost = baseTotal * infillCostMultiplier;
            setInfillCost(infCost.toFixed(2));

            const totalObject = baseTotal + matCost + colCost + infCost;
            setOrderTotal(totalObject.toFixed(2));
        } catch (error) {
            console.error("Error calculating totals:", error);
        }
    }, [material, color, infill, props.STLArray, materialCostMultiplier, colorCostMultiplier, infillCostMultiplier]);

    const style = {};

    function plusIterator(index) {
        const originalArray = [...props.STLArray];
        originalArray[index].quantity += 1;
        props.setSTLArray(originalArray);
    }

    function minusIterator(index) {
        const originalArray = [...props.STLArray];
        if (originalArray[index].quantity <= 1) {
            originalArray.splice(index, 1);
        } else {
            originalArray[index].quantity -= 1;
        }
        props.setSTLArray(originalArray);
    }

    function cartMaker() {
        props.setCart({
            STLArray: props.STLArray,
            material: material,
            color: color,
            infill: infill,
            orderTotal: orderTotal,
            totalPrintTime: totalPrintTime,
            materialCost: materialCost,
            colorCost: colorCost,
            infillCost: infillCost
        });
        navigate('/Cart');
    }

    return (
        <Container className="OptionWindow">
            <Card className='OptionCard'>
            <Row className="OptionsHeader align-items-center">
                <Col xs={12} className="text-center">
                    <h1>Customize Your Order</h1>
                </Col>
            </Row>

            <br/>
            <Button variant='primary' onClick={() => navigate('/upload')}>Back to upload page</Button>
            
            <br />
            <Row className="STLViewerArray justify-content-between">
                {props.STLArray.map((object, index) => (
                    <Col key={index} className="STLViewerWindow" xs={12} sm={12}>
                        <Col xs={12} className="LeftSTLWindow">
                        <CustomSTLViewer
                            url={`https://printforgers.com/backend/fileSender?id=${encodeURIComponent(object.id)}&fileName=${encodeURIComponent(object.fileName)}`}
                        />
                            <p>Dimensions:</p>
                            <p>{object.dimensions.gcodex.toFixed(2)}mm x {object.dimensions.gcodey.toFixed(2)}mm x {object.dimensions.gcodez.toFixed(2)}mm</p>
                        </Col>
                        <Col className="RightSTLWindow">
                            <p>File name: {object.fileName}</p>
                            <p>Price: ${(object.price * object.quantity).toFixed(2)}</p>
                            <div className="Quantity">
                                <p>Quantity:</p>
                                <div className="STLControls">
                                    <Button variant="primary" className="MinusButton" onClick={() => { minusIterator(index) }}>-</Button>
                                    <input className="form-control mx-2" value={object.quantity}  readOnly={true}/>
                                    <Button variant="primary" className="PlusButton" onClick={() => { plusIterator(index) }}>+</Button>
                                </div>
                            </div>
                        </Col>
                    </Col>
                ))}
            </Row>
            <hr />
            <h1>Options for your order</h1>
            <p>Current order total: ${orderTotal}</p>
            <p>Estimated arrival time: {Math.ceil(totalPrintTime / 60 / 60 / 12) + 2}-{Math.ceil(totalPrintTime / 60 / 60 / 12) + 9} business days</p>
            <Row className='OptionChanger justify-content-between'>
                <Col xs={12} md={3} className='OptionChangerItem' onClick={() => { setOptionStep("Material") }}>
                    <p>Material: {material}</p>
                    <p>Added cost: ${materialCost}</p>
                </Col>
                <Col xs={12} md={3} className='OptionChangerItem' onClick={() => { setOptionStep("Color") }}>
                    <p>Color: {color}</p>
                    <p>Added cost: ${colorCost}</p>
                </Col>
                <Col xs={12} md={3} className='OptionChangerItem' onClick={() => { setOptionStep("Infill") }}>
                    <p>Infill: {infill}%</p>
                    <p>Added cost: ${infillCost}</p>
                </Col>
            </Row>
            <br/>
            <br />
            {optionStep === "AddToCart" ? (
                <>
                </>
            ) : (
                <>
                    <p>Scroll down to pick your options</p>
                    <hr />
                </>
            )}

            {optionStep === "Material" && <SelectMaterial setMaterialCostMultiplier={setMaterialCostMultiplier} STLArray={props.STLArray} setOptionStep={setOptionStep} setMaterial={setMaterial} baseTotal={baseTotal} />}
            {optionStep === "Color" && <SelectColor setColorCostMultiplier={setColorCostMultiplier} STLArray={props.STLArray} setOptionStep={setOptionStep} material={material} color={color} setColor={setColor} baseTotal={baseTotal} />}
            {optionStep === "Infill" && <SelectInfill setInfillCostMultiplier={setInfillCostMultiplier} STLArray={props.STLArray} setOptionStep={setOptionStep} setInfill={setInfill} baseTotal={baseTotal} />}
            {optionStep === "AddToCart" && <>
                <br />
                <p>Click the boxes above to make further changes to your order</p>
                <Button variant='primary' onClick={cartMaker}>Add to cart</Button>
            </>}
            </Card>
        </Container>
    );
}

export default Options;
