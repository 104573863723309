import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { HttpPopupContext } from "../HTTPPopup/HttpPopupContext";

function SelectInfill(props) {
    const [infillList, setInfillList] = useState([]);
    const { setResponse } = useContext(HttpPopupContext);

    useEffect(() => {
        const fetchData = async () => { 
            try {
                const response = await axios.get("https://printforgers.com/backend/HTTPGetInfillPercentages");
                setInfillList(response.data.results);
                //console.log(response.data.results);
            } catch (error) {
                console.error('Error fetching data: ', error);
                setResponse(error.response);
            }
        };
        fetchData();
    }, []);

    return (
        <div className='SelectMaterialList'>
            <h1>Pick your infill</h1>
            <p className="MaterialListDescription">*This is the percentage of filler that will be inside your print. The higher the percentage, the less hollow it will be*</p>
            {infillList.map((object, index) => (
                <div key={index}>
                    <Row className='Material justify-content-between'>
                        <Col xs={6} className='Description'>
                            <p>{object.percentInfill}% Infill</p>
                        </Col>
                        <Col xs={2} className='MaterialControls'>
                            <p>Price: +${(props.baseTotal * object.base_infill_price_modifier).toFixed(2)}</p>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    props.setInfill(object.percentInfill);
                                    props.setOptionStep("AddToCart");
                                    props.setInfillCostMultiplier(object.base_infill_price_modifier);
                                }}
                            >
                                Select Infill
                            </Button>
                        </Col>
                    </Row>
                    <br />
                </div>
            ))}
        </div>
    );
}

export default SelectInfill;
