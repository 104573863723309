import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { HttpPopupContext } from "../HTTPPopup/HttpPopupContext";

function SelectColor(props) {
    const [colorList, setColorList] = useState([]);
    const { setResponse } = useContext(HttpPopupContext);

    useEffect(() => {
        const fetchData = async () => { 
            try {
                const response = await axios.get(`https://printforgers.com/backend/HTTPGetColors?material=${props.material}`);
                setColorList(response.data.results);
                //console.log(response.data.results);
            } catch (error) {
                console.error('Error fetching data: ', error);
                setResponse(error.response);
            }
        };
        fetchData();
    }, [props.material]);

    return (
        <div className='SelectMaterialList'>
            <h1>Pick your color</h1>
            <p className="MaterialListDescription">*This is the color of material that your entire order will be*</p>
            {colorList.map((object, index) => (
                <div key={index}>
                    <Row className='Material justify-content-between'>
                        <Col xs={8}>
                            <Row className="LeftColorColumn">
                                <img className="colorImage" src={`/filamentColors/${object.imageLocation}`} alt={`${object.color} color`} />
                                <Col xs={6} className='Description'>
                                    <p>{object.color}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2} className='MaterialControls'>
                            <p>Price: +${(props.baseTotal * object.base_color_price_modifier).toFixed(2)}</p>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    props.setColor(object.color);
                                    props.setOptionStep("Infill");
                                    props.setColorCostMultiplier(object.base_color_price_modifier);
                                }}
                            >
                                Select Color
                            </Button>
                        </Col>
                    </Row>
                    <br />
                </div>
            ))}
        </div>
    );
}

export default SelectColor;
